
html, body{
    height: 100%;
    background-color: #1b1b1b;
    width: 100%;
    margin: 0;
    padding: 0;
    color: white
}

.app{
    padding-top:20px;
}

.app h1{
    text-align: center;

    margin: 0;
    padding: 20px;
}